import type { Client } from '../useRestClient';
import { useRestClient } from '../useRestClient';
import { getCallbackUrl } from './getCallbackUrl';
import type { ChannelId } from '@/entity/channel';

type Params = {
  redirect: string;
  channelId: ChannelId;
  longSessionId: string;
  shortSessionId: string;
  nickname?: string;
  retry?: boolean;
};

type Response = string; // line認証のURLが直接返ってくる

export const { useGetAuthUrl, useAsyncGetAuthUrl } = useRestClient(
  'GetAuthUrl',
  'lexington',
  (cli: Client<Response>, params: Params) => {
    const callback = getCallbackUrl();
    return cli<Response>('line/auth', {
      query: {
        ...params,
        ...(callback ? { callback } : {}),
      },
      credentials: 'include',
    });
  }
);
