<script setup lang="ts">
import LineIcon from '~theme/assets/icons/fill/line.svg?component';
import ButtonFlatStyle from '~theme/components/atoms/styles/ButtonFlatStyle.vue';
import { useUserAgent } from '~theme/composables/useUserAgent';

const props = withDefaults(
  defineProps<{
    label?: string;
    disabled?: boolean;
    authUrl: string;
  }>(),
  { label: 'LINEで登録・ログイン', disabled: false }
);

const emit = defineEmits<{ (e: 'tryToLoginWithInstagramBrowser'): void }>();

const { userAgent } = useUserAgent();
const isUserAgentInstagram = computed(
  () => userAgent.value.browser.name === 'Instagram'
);
const tag = computed(() => {
  if (props.disabled) {
    return 'div';
  }
  if (isUserAgentInstagram.value) {
    return 'button';
  }
  return 'a';
});

const onClickLineLogin = () => {
  if (isUserAgentInstagram.value) {
    emit('tryToLoginWithInstagramBrowser');
  }
};

const attrs = useAttrs();
const listeners = computed(() =>
  Object.fromEntries(
    Object.entries(attrs).filter(
      ([k, v]) => k.startsWith('on') && typeof v === 'function'
    )
  )
);
</script>

<template>
  <ButtonFlatStyle variant="line" :disabled="disabled">
    <component
      :is="tag"
      v-bind="listeners"
      :href="authUrl"
      :class="$style.button"
      @click="onClickLineLogin"
    >
      <LineIcon :class="$style.button__logo" />
      <hr
        :class="[
          $style.button__hr,
          { [$style[`button__hr--disabled`]]: disabled },
        ]"
      />
      <span :class="$style.button__title">{{ label }}</span>
    </component>
  </ButtonFlatStyle>
</template>

<style lang="scss" module>
$logo-size: 24px;
$logo-padding: 8px;
$logo-area-size: $logo-size + $logo-padding * 2;

.button {
  $root: &;
  $horizontal-gutter: 1.4em;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 55px;
  padding-left: calc(#{$logo-area-size} + #{$horizontal-gutter});
  padding-right: $horizontal-gutter;
  font-size: 16px;

  &__logo {
    position: absolute;
    left: 8px;
    top: 50%;
    width: $logo-size;
    transform: translateY(-50%);
  }

  &__hr {
    position: absolute;
    display: block;
    border-color: transparent;
    border-left: 1px solid $color-border-low-emphasis;
    left: $logo-area-size;
    height: 100%;
    top: 0;
    bottom: 0;

    &--disabled {
      border-left-color: $color-border-low-emphasis;
    }
  }

  &__title {
    font-weight: bold;
  }
}
</style>
