export const getCallbackUrl = () => {
  const {
    public: { serverEnv },
  } = useRuntimeConfig();

  const { hostname } = window.location;

  // alpha環境でshadowのホスト名が*.400f.jpの場合はcallbackを指定する。
  // これでローカル環境で立ち上げているときにcallbackがローカル環境を向くようになる。
  // 詳しくは /components/templates/LineCallbackTemplate.vue のコメントを参照
  if (serverEnv !== 'production' && hostname.endsWith('.400f.jp')) {
    return `${new URL(window.location.href).origin}/line/callback`;
  }
  return undefined;
};
